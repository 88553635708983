import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { AuthService } from './auth.sevice';


@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  constructor(public authenticationService: AuthService) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const accessToken: string = this.authenticationService.token;
    const bearer: string = this.authenticationService.bearer;
    // set global application headers.
    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json'
      }
    });
    // Set headers for requests that require authorization.
    if (bearer) {
      let authenticatedRequest = request.clone({
        setHeaders: {
          'Authorization': `Basic ${this.authenticationService.bearer}` ,
        }
      });
      if (accessToken) {
        authenticatedRequest = authenticatedRequest.clone({
          setHeaders: {
            'survey-token': `${accessToken}`,
          }
        });
      }
      // Request with authorization headers
      return next.handle(authenticatedRequest);
    } else {
      // Request without authorization header
      return next.handle(request);
    }
  }
}