import { Injectable } from '@angular/core';

export class AuthService {

  private token0: string;
  private bearer0: string = 'bGFuZGluZzpsYW5kaW5n';

  public get token(): string {
    return this.token0;
  }

  public set token(token: string) {
    this.token0 = token;
  }

  public get bearer(): string {
    return this.bearer0;
  }

}