import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: 'survey', loadChildren: () => import('./main/main.module').then((m) => m.MainModule) },
  { path: '', redirectTo: 'survey', pathMatch: 'full' },
];

// configures NgModule imports and exports
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {
  // no-op
}
